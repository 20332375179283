<template>
  <div class="thePageBody">
    <div class="goodsTitle" style="margin-top: 20px;">
      <i class="el-icon-s-home"></i>
      Home >
      {{$t('Pre-sale Event')}}
    </div>
    <div class="goodsBase">
      <div class="goodsBase_left">
        <div style="width: 800px; height: 600px;">
          <img v-show="information.base_media==''" width="600" v-if="information.use_yum==1" :src="`${information.yum_base_img}`" />
          <img v-show="information.base_media==''" width="600" v-else :src="`${information.img}`" />

          <video id="myVideo" width="100%" height="100%" controls v-show="information.base_media">
            <source :src="`${information.base_media}`" type="video/mp4">
            <source src="movie.ogg" type="video/ogg">
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </div>
      <div class="goodsBase_right">
        <p class="goodsBase_right_title" v-if="lang=='zh-CN'">{{information.item_name}}</p>
        <p class="goodsBase_right_title" v-else>{{information.item_en}}</p>
        <p><span>{{$t('Product Barcode')}}:</span>{{information.item_no}}</p>
        <p><span>{{$t('HS Code')}}:</span>{{information.hs_code}}</p>
        <p><span>{{$t('MOQ')}}:</span>{{information.purchase_spec}}</p>
        <p><span>{{$t('Carton Quantity')}}:</span>{{information.lot_spec}}</p>

        <div v-if="lang=='zh-CN'">
          <p><span>{{$t('Weight')}}:</span>{{information.weight}}</p>
          <p><span>{{$t('Filler')}}:</span>{{information.stuffing}}</p>
        </div>
        <div v-else>
          <p><span>{{$t('Weight')}}:</span>{{information.weight_en}}</p>
          <p><span>{{$t('Filler')}}:</span>{{information.stuffing_en}}</p>
        </div>

        <p v-if="lang=='zh-CN'"><span>{{$t('Material')}}:</span>{{information.material}}</p>
        <p v-else><span>{{$t('Material')}}:</span>{{information.material_en}}</p>

        <p><span>{{$t('Retail Price')}}:</span><span style="color:red;">￥{{information.sale_price.toFixed(2)}}</span></p>
        <p><span>{{$t('Wholesale price')}}:</span> <span style="color:red;font-weight:800;">￥{{information.base_price.toFixed(2)}}</span></p>
        <div style="margin:10px 0;">
          <el-input-number v-model="buy_num" :min="information.purchase_spec" :max="999" :step="information.purchase_spec" label="描述文字">
          </el-input-number>
        </div>
        <div class="addOrder" v-if="information.sku_status == 0" @click="clickGoods">{{$t('Place an order')}}</div>
      </div>
    </div>

    <div class="goodsInfo">
      <h1 class="goodsInfo_title">产品详情</h1>
      <div style="width:750px;">
        <div v-if="lang=='zh-CN'">
          <p><span>{{$t('Product features')}}:</span>{{information.features}}</p>
          <p><span>{{$t('usage method')}}:</span>{{information.usage}}</p>
          <p><span>{{$t('matters needing attention')}}:</span>{{information.notes}}</p>
        </div>
        <div v-else>
          <p><span>{{$t('Product features')}}:</span>{{information.features_en}}</p>
          <p><span>{{$t('usage method')}}:</span>{{information.usage_en}}</p>
          <p><span>{{$t('matters needing attention')}}:</span>{{information.notes_en}}</p>
        </div>
        <video id="info_media" width="100%" height="100%" controls v-show="information.info_media">
          <source :src="`${information.info_media}`" type="video/mp4">
          <source src="movie.ogg" type="video/ogg">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <img width="800" v-if="information.use_yum==1" :src="`${information.yum_info_img}`" />
      <img width="800" v-else :src="`${information.details_img}`" />
    </div>
  </div>
</template>
<script>
import { getPresellInfo, addPresellOrder } from "@/api/goods/presell.js";
export default {
  data() {
    return {
      abc: "asdfasdf",
      id: "",
      information: "",
      lang: "",
      buy_num: 0,
    };
  },
  watch: {
    information() {
      this.baseVideo();
      this.infoVideo();
    },
  },
  created() {
    this.getId();
    this.getLang();
  },
  methods: {
    baseVideo() {
      var video = document.getElementById("myVideo");
      video.src = this.information.base_media;
    },
    infoVideo() {
      var video = document.getElementById("info_media");
      video.src = this.information.base_media;
    },
    getLang() {
      // if (this.$i18n.locale == "zh-CN") {
      // 	this.lang = this.$i18n.locale
      // } else {
      // }
      this.lang = this.$i18n.locale;
    },
    getId() {
      var query = this.$route.query;
      this.id = query.id;
      getPresellInfo(this.id).then((res) => {
        let resData = res.data.data;
        this.information = resData;
        this.buy_num = resData.purchase_spec;
      });
    },
    clickGoods() {
      addPresellOrder(this.information.item_no, this.buy_num)
        .then((res) => {
          let resData = res.data;
          if (resData.data == "追加预售成功") {
            this.$message.success(this.$t("Append successfully"));
          } else {
            this.$message.success(this.$t("Successfully ordered"));
          }
        })
        .catch((res) => {
          this.$message.error(res.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss">
	.thePageBody {
		width: 1400px;
		// background: #CCCCCC;
		margin: 0 auto;
	}

	.goodsTitle {
		margin-bottom: 20px;
	}

	.goodsBase {
		// border: 1px solid red;
		display: flex;

		&_right {
			// border:1px solid blue;
			margin-left: 100px;
			min-width: 100px;
			width: 100%;

			&_title{
				font-size: 18px;
				font-weight: 800;
			}
			p {
				// border: 1px solid yellow;
				margin-top: 10px;

				span {
					// font-weight: 700;
					margin-right: 5px;
				}
			}

			.addOrder {
				width: 250px;
				background: #218da0;
				height: 60px;
				text-align: center;
				line-height: 60px;
				color: white;
				font-weight: 900;
				cursor: pointer;
				margin-top: 10px;
			}
		}
	}

	.goodsInfo {
		// border:1px solid red;
		margin-top: 20px;

		&_title {
			font-weight: 900;
			font-size: 28px;
			margin: 10px 0;
			width: 800px;
			border-bottom: 3px solid #218da0;
			padding: 8px 0;
		}

		p {
			margin-top: 10px;

			span {
				font-weight: 900;
			}
		}
	}
</style>
